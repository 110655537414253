'use strict';
'esversion: 6';

import jquery from 'jquery';
import axios from 'axios';
import i18n from './i18n.js';
export default (window.$ = window.jQuery = jquery);

require('es6-promise').polyfill();

console.log("Server backend at "+process.env.BACKEND_URL);

import '../scss/main.scss';

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
    Object.keys = (function() {
        let hasOwnProperty = Object.prototype.hasOwnProperty;
        let hasDontEnumBug = !{ toString: null }.propertyIsEnumerable(
            'toString'
        );
        let dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor',
        ];

        let dontEnumsLength = dontEnums.length;

        return function(obj) {
            if (
                typeof obj !== 'function' &&
                (typeof obj !== 'object' || obj === null)
            ) {
                throw new TypeError('Object.keys called on non-object');
            }

            let result = [];
            let prop;
            let i;

            for (prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                    result.push(prop);
                }
            }

            if (hasDontEnumBug) {
                for (i = 0; i < dontEnumsLength; i++) {
                    if (hasOwnProperty.call(obj, dontEnums[i])) {
                        result.push(dontEnums[i]);
                    }
                }
            }
            return result;
        };
    })();
}

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

jquery(function() {
    const VINREGEX = /^[A-HJ-NPR-Z0-9]{17}$/i;
    let language = getUrlParameter('lang');
    let externalVin = getUrlParameter('vin');

    console.log(language, externalVin);

    let SERVER_API = {
        getFuseCard: process.env.BACKEND_URL + '/api/getFuseCard?brand=' + process.env.BRAND + '&' /* vin */,
        fileDownload: process.env.BACKEND_URL + '/api/fileDownload?brand=' + process.env.BRAND + '&token=' + process.env.BRAND + '/public&', /* fileType,fileId */
    };

    let browserLanguage = navigator.language || navigator.userLanguage;

    if (language.length != 2) {
        language = browserLanguage.split('-').shift();
    }

    if (Object.keys(i18n).indexOf(language) < 0) {
        language = 'en';
    }

    jquery.each(i18n[language], function(idx, val) {
        let elem = jQuery('.' + idx);

        if (idx.indexOf('form-') >= 0) {
            elem.attr('placeholder', val);
        } else if (idx.indexOf('data-') >= 0) {
            elem.attr('data-text', val);
        } else {
            elem.html(val);
        }
    });

    if(externalVin != '') {
        jQuery('.osb-vin').val(externalVin);

        if (VINREGEX.test(jquery('.osb-vin').val())) {
            jquery('.button').addClass('active');
        } else {
            jquery('.button').removeClass('active');
        }
    }

    jquery('.container').fadeIn(250);

    jquery('.osb-vin').change(function(e) {
        if (VINREGEX.test(jquery('.osb-vin').val())) {
            jquery('.button').addClass('active');
        } else {
            jquery('.button').removeClass('active');
        }
    });

    jquery('.osb-vin').on('keyup', function() {
        if (VINREGEX.test(jquery('.osb-vin').val())) {
            jquery('.button').addClass('active');
        } else {
            jquery('.button').removeClass('active');
        }
    });
    let loadingTimeout = null;
    jquery('.button').click(function(e) {
        if (VINREGEX.test(jquery('.osb-vin').val())) {
            loadingTimeout = setTimeout(function() {
                jquery('.loading').hide();
                jquery('.server-error').show();
            }, 30000);
            jquery('.loading').show();
            jquery('.error').hide();
            jquery.ajax({
                url: SERVER_API['getFuseCard'] + 'vin=' + jquery('.osb-vin').val().toLocaleUpperCase(),
                dataType: 'json',
                type: 'POST',
                success: function(response) {
                    jquery('.loading').hide();
                    clearTimeout(loadingTimeout);
                    console.log('success ', response);
                    if (response.status === 'OK') {
                        downloadFile(response.fileId, response.fileType);
                    } else {
                        jquery('.' + response.status).show();
                    }
                },
                error: function(a, b, c) {
                    console.log('error');
                },
            });
        }
    });

    function downloadFile(fileId, fileType) {
        console.log('Download File', fileId, fileType);
        axios({
            method: 'GET',
            url: SERVER_API['fileDownload'] + 'fileId=' + fileId + '&fileType=' + fileType,
            responseType: 'blob',
        }).then(function(response) {
            let fileName = 'file.pdf';
            if (response.headers && response.headers['content-disposition']) {
                fileName = response.headers['content-disposition'].replace(/"/g, '').split('filename=')[1];
            }
            let newBlob = new Blob([response.data], { type: 'application/pdf' });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
            }, 100);
        });
    }
    jquery('.info').click(function(e) {
        jquery(e.currentTarget).toggleClass('active');
    });
    jquery(document).on('keyup', function(e) {
        if (e.keyCode === 27) {
            jquery('.info').removeClass('active');
        }
    });
});
